import React from "react"
import styled from "styled-components";

import Arrow from "../icons/arrow.svg";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import ContainerSmall from "../styles/ContainerSmall";


const Wrapper = styled.div`
  padding-bottom: 60px;
  @media (min-width: ${props => props.theme.screenMd}) {
    padding-right: 20%;
    padding-left: 20%;
  }
`

const ContactWrapper = styled.form`
  display: flex;
  flex-direction: column;

`;


const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 150%;
  color: #000000;
 
`;

const StyledInput = styled.input`
  background: #EAE8DC;
  margin-bottom: 15px;
  border: none;
  box-shadow: none;
  height: 2.5rem;
  padding: .3125rem;
  font-size: 1rem;
`;


const StyledButtonLink = styled.div`
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.red};
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  width: 9.5rem; // So we can keep this static on hover
  height: 3.0625rem;
  position: relative;
  &:hover {
    cursor: pointer;
  }

  span {
    transition: all, ease-in-out, 300ms;
    position: absolute;
    left: 30px;
    pointer-events: none;
  }

  input {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    &:hover {
      cursor: pointer;
    }
  }
  svg {
    opacity: 0;
    transition: all, ease-in-out, 300ms;
    position: absolute;
    right: 30px;
    fill: #fff;
    path {
      fill: #fff;
    };
  }
  &:hover,
  &:focus {
    font-weight: bold;
    background: linear-gradient(0deg, rgba(26, 26, 26, 0.2), rgba(26, 26, 26, 0.2)), #D74339;
    svg {
      opacity: 1;
      right: 15px
    }
    span {
      left: 15px;
    }
  }
}
 `;


const ResponseText = styled.span`

  max-width: 100%;
  margin-bottom: 20px;
  font-weight: bold;
`;


class ReferralForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: ' ',
      institution: ' ',
      theiremail: ' ',
      theirinstitution: ' ',
      submitting: null,
      submitted: false,
      message: 'No message submitted', // set this to string since hubspot requires it
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInstitution = this.handleInstitution.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleTheirEmail = this.handleTheirEmail.bind(this);
    this.handleTheirInstitution = this.handleTheirInstitution.bind(this);
  }


  handleEmail(event) {
    this.setState({'email': event.target.value});
  }

  handleTheirEmail(event) {
    this.setState({'theiremail': event.target.value});
  }

  handleInstitution(event) {
    this.setState({'institution': event.target.value});
  }

  handleTheirInstitution(event) {
    this.setState({'theirinstitution': event.target.value});
  }

  handleSubmit(event) {

    event.preventDefault();


    this.setState({
      'submitting': true
    });

    // First, make sure we have an email address
    if (this.state.email.length < 1) {
      this.setState({'responsemessage': 'Please enter a valid email address.'});
      this.setState({'submitting': false});
      return;
    }


    // This is the format Hubspot needs for submissions
    let data = {
      // "submittedAt": Math.floor(Date.now()), // causing errors if submitted from the future, like on a browser on east coast time
      "pageUrl": document.URL,
      "context": {
        "pageUri": document.URL,
      },
      "fields": [
        {
          "name": "email",
          "value": this.state.email
        },
        {
          "name": "your_institution",
          "value": this.state.institution
        },
        {
          "name": "their_email",
          "value": this.state.theiremail
        },
        {
          "name": 'their_company_name',
          "value": this.state.theirinstitution
        }
      ]
    };


    const hubspotUrl = 'https://api.hsforms.com/submissions/v3/integration/submit/7009511/ef751288-486d-4d19-ad7b-7dd2594865db';


    fetch(hubspotUrl, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
      .then((response) => {

        return response.json();
      })
      .then((responseJson) => {
        this.setState({'submitting': false});
        console.log(responseJson);
        if (responseJson.status === 'error') {

          this.setState({'submitted': false});
          if (responseJson.errors[0].errorType === 'INVALID_EMAIL') {
            this.setState({'responsemessage': 'Please enter a valid email address.'});
          } else {
            this.setState({'responsemessage': 'There was an error with your submission. Please try again, or email us at info@cleancatalog.com.'});
          }
        } else {
          this.setState({'responsemessage': 'Thanks! We\'ll be in touch shortly'});
          this.setState({'submitted': true});
        }

      })
      .catch((error) => {
        this.setState({'submitting': false});
        this.setState({'submitted': false});
        this.setState({'responsemessage': 'There was an error with your submission. Please try again, or email us at info@cleancatalog.com.'});
      })
    ;


  }


  render() {


    if (this.state.submitted) {
      return <Wrapper><ResponseText>{this.state.responsemessage}</ResponseText></Wrapper>
    }

    if (this.state.submitting) {
      return (<Wrapper>
          <ContainerSmall>
            <h4>Submitting...</h4>
            <Loader
              type="Puff"
              color="#0D3745"
              height={100}
              width={100}
            />
          </ContainerSmall>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <ContainerSmall>

          <ContactWrapper onSubmit={this.handleSubmit}>

            <ResponseText>{this.state.responsemessage}</ResponseText>


            <StyledLabel>
              Your Email
              <StyledInput type="text" name="name" onChange={this.handleEmail}/>
            </StyledLabel>
            <StyledLabel>
              Your Institution
              <StyledInput type="text" name="institution" onChange={this.handleInstitution}/>
            </StyledLabel>

            <StyledLabel>
              Their Email
              <StyledInput type="text" name="name" onChange={this.handleTheirEmail}/>
            </StyledLabel>

            <StyledLabel>
              Their Institution
              <StyledInput type="text" name="theirinstitution" onChange={this.handleTheirInstitution}/>
            </StyledLabel>


            <StyledButtonLink>
              <input type={'submit'} value={'Submit'} onClick={this.handleSubmit}/>
              <span>Submit</span>
              <Arrow/>
            </StyledButtonLink>

          </ContactWrapper>
        </ContainerSmall>
      </Wrapper>
    )


  }

}

export default ReferralForm
