import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import styled from "styled-components";
import BuyerIntro from "../components/buyersguide/BuyerIntro";
import Start from "../components/buyersguide/Start";
import ContainerSmall from "../styles/ContainerSmall";
import ReferralForm from "../components/ReferralForm";


const TextSection = styled(ContainerSmall)`
  display: flex;
  flex-direction: column;

  > div {
    padding: 30px;
    @media (min-width: ${props => props.theme.screenMd}) {
      padding: 60px 60px 20px 60px;
    }

    h2 {
      font-weight: 900;
      font-size: ${props => props.theme.fontXl};
      color: ${props => props.theme.blue};
      text-align: center;
    }

    p, ul {
      font-size: ${props => props.theme.fontMdSm};
      text-align: center;
    }



  }
`


export default function Referrals() {


  return (
    <Layout grayFooter={true}>

      <BuyerIntro
        title={'Referral Program'}
        text={'        <h2>Share the Knowledge, Refer a Friend to Clean Catalog</h2>\n' +
          '\n' +
          '      <p>Have friends in the industry? Odds are, they’ll love Clean Catalog software just as much as your students and staff do! Due to popular request, we’re happy to offer a referral program for anyone who shares Clean Catalog with their friends in the market for catalog or curriculum software.</p>\n' +
          '\n' +
          '<p>It’s quick and easy – just let us know which new institution you referred to us, or refer them using the form below. For every successful referral, you will receive a discount on your upcoming year’s software renewal and they will receive a discount on their initial year.</p>'}
      />

      <Start
        title={'Simple for you.  A game-changer for them.'}
        text={' Referring someone to Clean Catalog is so easy that we’ve condensed it to three simple steps:'}
        stepOneTitle={'Spread the word! '}
        stepOneText={'<p>Tell your academic friends (or arch rivals!) about Clean Catalog. Either tell them yourself, or use the form below and we’ll reach out on your behalf.</p>'}
        stepTwoTitle={'We Work with Them'}
        stepTwoText={'<p>We\'ll work with the folks you referred to make sure the fit is good on both sides, and to launch their product.</p>'}
        stepThreeTitle={'Get Your Discount'}
        stepThreeText={'<p>We\'ll automatically apply a discount to your next year\'s cost.</p>'}

      />

      <TextSection>
        <div>
        <h2>Refer a friend to Clean Catalog</h2>
        <p>You get a discount, they get a discount. It’s a win-win.</p>
        </div>
      </TextSection>

      <ReferralForm />








    </Layout>
  )

}


export const Head = () => {
  return (
    <Seo
      title='Referral Program'
      description='Our discount program for referring customers to Clean Catalog.'
      canonical={'/referral/'}
    />
  )
};


